<template>
	<div class="flex-row-auto h-100" id="kt_profile_aside">
		<div class="card card-custom" style="height: 100%">
			<div class="d-flex flex-column justify-content-around align-items-center p-15" style="height: 100%">
				<div class="symbol symbol-60 symbol-circle symbol-xl-90">
					<div
						class="symbol-label"
						style="background-image: url('/metronic/theme/html/demo1/dist/assets/media/users/300_21.jpg');"
					>
						<img width="50" :src="'/media/img/ICON-' + (adminSite.icon ? adminSite.icon : 'dish') + '.png'" />
					</div>
					<i class="symbol-badge symbol-badge-bottom bg-success"></i>
				</div>
				<div class="d-flex flex-column align-items-center mw-100">
					<router-link :to="{ name: 'site', params: { id: adminSite.id } }" style="max-width:100%;">
						<h2 id="sitename" class="my-2 text-center card-title font-weight-bolder site-name">
							{{ adminSite.name }}
						</h2>
					</router-link>
					<div class="text-muted mb-2">Site: {{ adminSite.id }}</div>
				</div>
				<div>
					<span class="btn btn-light-warning font-weight-bold" v-if="!adminSite.showQuota" @click="toggleQuota">
						<span v-if="adminSite.quotaStatus == 'added'">Show</span><span v-else>Add</span> Quota Alert
					</span>
					<span class="btn btn-light-warning font-weight-bold" v-else @click="toggleQuota">
						Hide Quota Alert
					</span>
				</div>
				<div v-if="!adminSite.loading && adminSite.starlinkServiceLines.length>0">
					<span class="btn btn-light-warning font-weight-bold" v-if="!adminSite.showStarlinkSubscription" @click="toggleStarlinkSubscription">
						<span v-if="adminSite.starlinkQuotaStatus == 'added'">Show</span><span v-else>Add</span> Starlink Quota Alert
					</span>
					<span class="btn btn-light-warning font-weight-bold" v-else @click="toggleStarlinkSubscription">
						Hide Starlink Subscription
					</span>
				</div>
				<div v-if="adminSite.loading">
					<span class="btn btn-light-warning font-weight-bold">
						<span>Loading... </span> Starlink Quota Alert
					</span>
				</div>
				<div>
					<span
						class="d-inline-block"
						v-b-tooltip="{
							title: adminSite.data.linkedAccounts && adminSite.data.linkedAccounts.length > 0 ? 'Must remove all linked accounts to delete.' : null,
							placement: 'top',
							variant: 'danger',
						}"
					>
						<b-button
							v-b-modal="'myModal' + adminSite.data.SiteId"
							:disabled="adminSite.data.linkedAccounts && adminSite.data.linkedAccounts.length > 0"
							variant="outline-danger"
							><i class="flaticon2-trash" /> Delete Site</b-button
						>
					</span>
					<b-modal
						:id="'myModal' + adminSite.data.SiteId"
						title="Delete Site"
						ok-title="Delete"
						ok-variant="danger"
						@ok="deleteSite(adminSite.data.SiteId)"
					>
						<p class="my-4">Are you sure you want to delete site {{ adminSite.data.Name }} ({{ adminSite.data.SiteId }})?</p>
					</b-modal>
					<b-modal id="redirecting" hide-footer>
						<div class="d-block text-center">
							<h3>Redirecting to the admin page...</h3>
						</div>
					</b-modal>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TOGGLE_QUOTA, TOGGLE_STARLINK_SUBSCRIPTION } from '@/core/services/store/admin.module';

export default {
	name: 'SiteProfileToolPanel',
	data() {
		return {
			loaded: false,
		};
	},
	components: {
		// ITCSpinner,
	},
	computed: {
		...mapGetters(['adminSite']),
	},
	methods: {
		toggleQuota() {
			let payload = this.adminSite.showQuota;
			this.$store.commit(TOGGLE_QUOTA, payload);
		},
		toggleStarlinkSubscription() {
			let payload = this.adminSite.showStarlinkSubscription;
			this.$store.commit(TOGGLE_STARLINK_SUBSCRIPTION, payload);
		},
		deleteSite(id) {
			this.$http
				.post('/deletesite', { id: id })
				.then(res => {
					if (res.status === 200) {
						this.$bvModal.show('redirecting');
						setTimeout(() => {
							this.$router.push('/admin');
							this.success = false;
						}, 2000);
					}
				})
				.catch(err => {
					console.log('Delete Site Error:', err);
				});
		},
		adjustSiteNameSize(val, height) {
			let size = val;
			if (height > 46) {
				document.getElementById('sitename').style.fontSize = `${size}em`;
				size = size - 0.1;
				let ht = document.getElementById('sitename').offsetHeight;
				this.adjustSiteNameSize(size, ht);
			}
		},
	},
	updated() {
		let height = document.getElementById('sitename').offsetHeight;
		this.adjustSiteNameSize(1.8, height);
	},
};
</script>

<style scoped>
a:hover h2.site-name {
	color: rgb(10, 10, 141);
}
.site-name {
	color: #181c32;
}
</style>
